import { ReactElement } from "react";
import { PRESS, MORE_PRESS } from "./press";
import styles from "./About.module.scss";

type TPress = {
  press: string;
  url: string | null;
};

const About = (): ReactElement => (
  <div className={styles.container}>
    <div className={styles.titleContainer}>
      <p>BIO</p>
      <div className={styles.line} />
    </div>

    <p className={styles.text}>
      Andrés Reisinger (b.1990) is one of the most sought-after digital artists
      of the 21C. Originally from Argentina, he now creates carefully curated
      projects in his studio in Barcelona. His instantly-recognisable dream-like
      imagery have drawn interest from a plethora of multi-million-dollar
      collectors, brands and international art galleries alike. He is named as
      one of “Forbes 30 Under 30” artists creating and designing the future of
      the arts and is the winner of the “Young Guns” Art Directors Club New York
      City. His market has grown from strenght to strenght, with recent works
      fetching up to half a million dollars auction at Christie's NYC Evening
      Sale (Arcadia, $525,000, Christie's, November 2021).
    </p>

    <div className={styles.titleContainer}>
      <p>PRESS</p>
      <div className={styles.line} />
    </div>

    <div className={styles.pressContainer}>
      <div>
        {PRESS.map(({ press, url }: TPress) => (
          <a
            href={url ? url : ""}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.press}
            key={press}
          >
            <p>·</p> {press}
          </a>
        ))}
      </div>
      <div>
        {MORE_PRESS.map(({ press, url }: TPress) =>
          url ? (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.press}
              key={press}
            >
              <p>·</p> {press}
            </a>
          ) : (
            <div className={styles.press} key={press}>
              <p>·</p> {press}
            </div>
          )
        )}
      </div>
    </div>

    <div className={styles.titleContainer}>
      <p>AWARDS & MERITS</p>
      <div className={styles.line} />
    </div>

    <div className={styles.listContainer}>
      <a
        href={
          "https://www.forbes.com/profile/andres-reisinger/?sh=68798574fda2"
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>·</p> Forbes 30 under 30 | Arts & Style, Forbes
      </a>
      <a
        href={
          "https://www.oneclub.org/awards/youngguns/17/-bio/yg17-andres-reisinger"
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>·</p> Young Gun | Art Directors New York City
      </a>
      <a
        href={
          "https://www.revistaad.es/decoracion/ad100/articulos/ad100-andres-reisinger-espacios-digitales-surrelistas/24601"
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>·</p> Design Award | Architectural Digest, AD
      </a>
      <a
        href={"https://awards.archiproducts.com/team/andres-reisinger/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>·</p> Archiproducts
      </a>
    </div>

    <div className={styles.titleContainer}>
      <p>EXHIBITIONS & SHOWS</p>
      <div className={styles.line} />
    </div>

    <div className={styles.listContainer}>
      <div>
        <p>·</p> 18 1000 Vases | Espace Commines, París, FR
      </div>
      <div>
        <p>·</p> 19 QTS Curated by CHAMBER NYC | Buenos Aires, AR
      </div>
      <div>
        <p>·</p> 19 Complicated Sofa | Milan Design Week, Fuorisalone, Milan, IT
      </div>
      <div>
        <p>·</p> 19 Job Interview | Last Resort Gallery, Copenhagen, DK
      </div>
      <div>
        <p>·</p> 19 Maze | 1stdibs Gallery, New York City, US
      </div>
      <div>
        <p>·</p> 19 Hortensia Chair | Montoya Gallery, Barcelona, ES
      </div>
      <div>
        <p>·</p> 20 New Show | Last Resort Gallery, Copenhagen, DK
      </div>
      <div>
        <p>·</p> 20 Hortensia Chair | Collectible Fair, Brussels, BE
      </div>
      <div>
        <p>·</p> 20 Hortensia Chair | Design Museum Gent, Ghent, BE
      </div>
      <div>
        <p>·</p> 21 The Shipping | Nifty Gateway, US
      </div>
    </div>

    <div className={styles.titleContainer}>
      <p>TALKS</p>
      <div className={styles.line} />
    </div>

    <div className={styles.listContainer}>
      <div>
        <p>·</p> ↓ As co-founder of Six N. Five
      </div>
      <div>
        <p>·</p> 16 Lad | Lima, PE
      </div>
      <div>
        <p>·</p> 16 Udem | Monterrey, MEX
      </div>
      <div>
        <p>·</p> 16 Offf | Barcelona, ES
      </div>
      <div>
        <p>·</p> 16 Congreso 4GN | Bogotá, COL
      </div>
      <div>
        <p>·</p> 17 Showcase ITCH | Guatemala, GUA
      </div>
      <div>
        <p>·</p> 17 Brief | Madrid, ES
      </div>
      <div>
        <p>·</p> ↓ As Founder & Director of Reisinger Studio
      </div>
      <div>
        <p>·</p> 20 Elisava | Barcelona, ES
      </div>
      <div>
        <p>·</p> 20 IED | Barcelona, ES
      </div>
      <div>
        <p>·</p> 20 Barreira A+D | Valencia, ES
      </div>
      <div>
        <p>·</p> 20 DArA | Buenos Aires, AR
      </div>
      <div>
        <p>·</p> 21 ETHDenver | Denver, CO, US
      </div>
      <div>
        <p>·</p> 21 Hortensia | Minim, Barcelona, ES
      </div>
    </div>
  </div>
);

export default About;
