import { ReactElement } from "react";
import {
  TWITTER,
  INSTAGRAM,
  REISINGER_MAIL_CONTACT,
} from "../../constants/ExternalLinks";
import styles from "./Footer.module.scss";

const Footer = (): ReactElement => (
  <footer className={styles.container}>
    <div className={styles.line} />
    <div className={styles.content}>
      <div className={styles.topInfo}>
        <div className={styles.contactData}>
          <a
            href={
              "https://www.google.com/maps/place/Carrer+de+Llull,+70,+08005+Barcelona,+Espa%C3%B1a/data=!4m2!3m1!1s0x12a4a31a0e4bb789:0xeb580f9a9aca91d8?sa=X&ved=2ahUKEwiY8bvT5aLuAhUBDmMBHR0mBT4Q8gEwAHoECAMQAQ"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            C/ ESPRONCEDA, 123. 08005 BARCELONA, SPAIN
          </a>
          <a
            href={`mailto:${REISINGER_MAIL_CONTACT}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            INFO@REISINGER.STUDIO
          </a>
        </div>
        <a className={styles.backToTop} href="#top">
          <p className={styles.buttonText} />
        </a>
        <div className={styles.socialMedia}>
          <a href={INSTAGRAM} target="_blank" rel="noopener noreferrer">
            INSTAGRAM
          </a>
          <p>/</p>
          <a href={TWITTER} target="_blank" rel="noopener noreferrer">
            TWITTER
          </a>
        </div>
      </div>
      <p>©{new Date().getFullYear()} REISINGER STUDIO</p>
    </div>
  </footer>
);

export default Footer;
