import { ReactNode, ReactElement } from "react";
import useCollapse from "react-collapsed";
import styles from "./ButtonCollapse.module.scss";

const ButtonCollapse = ({
  children,
  name,
}: {
  name: string;
  children: ReactNode;
}): ReactElement => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <>
      <button className={styles.option} {...getToggleProps()}>
        <p>{isExpanded ? "[-]" : "[+]"}</p> {name}
      </button>

      <div className={styles.hidedSection} {...getCollapseProps()}>
        {children}
      </div>
    </>
  );
};

export default ButtonCollapse;
