export const APP_NAME: string = process.env.REACT_APP_NAME ?? "";
export const APP_AUTHOR: string = process.env.REACT_APP_AUTHOR ?? "";
export const API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL ?? "";
export const API_CLIENT_ID: string = process.env.REACT_APP_API_CLIENT_ID ?? "";
export const API_DEFAULT_SORT: string =
  process.env.REACT_APP_API_DEFAULT_SORT ?? "";
export const API_DEFAULT_ORDER: string =
  process.env.REACT_APP_API_DEFAULT_ORDER ?? "";
export const API_DEFAULT_LIMIT: number = Number(
  process.env.REACT_APP_API_DEFAULT_LIMIT
);
