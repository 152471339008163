export const PRESS = [
  {
    press: "Forbes",
    url: "https://www.forbes.com/profile/andres-reisinger/?sh=66f25f504fda",
  },
  {
    press: "Artnet",
    url: "https://news.artnet.com/market/virtual-furniture-nft-auction-1946103",
  },
  {
    press: "Dezeen",
    url: "https://www.dezeen.com/2021/02/23/andres-reisinger-the-shipping-digital-furniture-auction/",
  },
  {
    press: "AD Architectural Digest",
    url: "https://www.ad-magazin.de/article/andres-reisinger",
  },
  {
    press: "Highsnobiety",
    url: "https://www.highsnobiety.com/p/andres-reisinger-the-shipping-digital-furniture-auction/",
  },
  {
    press: "La Nacion",
    url: "https://www.lanacion.com.ar/lifestyle/andres-reisinger-mi-meta-es-deshacerme-tendencia-nid2536940/",
  },
  {
    press: "Nowness",
    url: "https://www.facebook.com/nowness/posts/10156753061922454/",
  },
  {
    press: "Fubiz",
    url: "https://www.instagram.com/p/ByPW-6GiLGz/",
  },
  {
    press: "Ignant",
    url: "https://www.ignant.com/2018/09/04/andres-reisingers-explorative-pastel-3d-renderings/",
  },
  {
    press: "Sight Unseen",
    url: "https://www.sightunseen.com/2019/02/quick-tiny-shows-3d-artist-andres-reisinger/",
  },
  {
    press: "Design Boom",
    url: "https://www.designboom.com/art/reisinger-studio-electric-nature-landscapes-05-12-2020/",
  },
  {
    press: "Design Wanted",
    url: "https://designwanted.com/design/reisinger-studio-interview/",
  },
  {
    press: "Surface Mag",
    url: "https://www.surfacemag.com/articles/andres-reisinger-hortensia-chair-digital-design/",
  },
  {
    press: "Neo2 Magazine",
    url: "https://www.neo2.com/silla-hortensia-andres-reisinger/",
  },
  {
    press: "Watts.Place",
    url: "https://www.instagram.com/p/CI8RN94lF9q/",
  },
  {
    press: "Metropolis Mag",
    url: "https://www.metropolismag.com/technology/andres-reisinger-the-shipping/",
  },
  {
    press: "The Future Laboratory",
    url: "https://www.thefuturelaboratory.com/hubfs/Luxury%20&%20Hospitality%20Futures%202018.pdf",
  },
  {
    press: "Visual Pleasure Mag",
    url: "https://www.visualpleasuremag.com/vp-talents/andres-reisinger",
  },
  {
    press: "Archiproducts",
    url: "https://awards.archiproducts.com/team/andres-reisinger/",
  },
  {
    press: "Fahrenheit Magazine",
    url: "https://fahrenheitmagazine.com/diseno/los-interiores-oniricos-y-fantasticos-que-crea-andres-reisinger",
  },
  {
    press: "Dezeen / Hortensia",
    url: "https://www.dezeen.com/2019/12/13/andres-reisinger-hortensia-chair-design/",
  },
  {
    press: "Color Review",
    url: "https://www.colorreview.net/post/entrevista-andres-reisinger",
  },
  {
    press: "Design Hunger",
    url: "https://designhunger.co/blog/pantone-crush-06?rq=reisinger",
  },
];

export const MORE_PRESS = [
  {
    press: "Design Week",
    url: null,
  },
  {
    press: "Art Power",
    url: null,
  },
  {
    press: "The Brand Identity",
    url: null,
  },
  {
    press: "Say hi to",
    url: null,
  },
  {
    press: "Fast Company",
    url: null,
  },
  {
    press: "Advertising Week",
    url: null,
  },
  {
    press: "Salone del Mobile Milano",
    url: null,
  },
  {
    press: "The Vanderbild",
    url: null,
  },
  {
    press: "Plastik Magazine",
    url: null,
  },
  {
    press: "Red Milk Magazine",
    url: null,
  },
  {
    press: "Konbini",
    url: null,
  },
  {
    press: "IDEAT",
    url: "https://ideat.thegoodhub.com/2021/04/20/hortensia-le-fauteuil-qui-vous-assoit-dans-un-champ-de-fleurs/",
  },
  {
    press: "NSS Magazine",
    url: "https://www.nssmag.com/en/art-design/26025/hortensia-armchair-moooi/image:338888",
  },
  {
    press: "Pambianco Design",
    url: "https://design.pambianconews.com/moooi-cura-la-realizzazione-della-hortensia-armchair-di-andres-reisinger-julia-esque/",
  },
  {
    press: "DAMN Magazine",
    url: "https://www.damnmagazine.net/product/hortensia-armchair/",
  },
  {
    press: "Collectible",
    url: "https://collectible.design/interview-andres-reisinger",
  },
  {
    press: "Harpers Bazaar Italia",
    url: "https://www.harpersbazaar.com/it/lifestyle/design/a36173635/hortensia-chair-moooi/",
  },
  {
    press: "Rivista Studio",
    url: "https://www.rivistastudio.com/nft-design/",
  },
  {
    press: "Passione Design",
    url: "https://www.passionedesign.it/news-design/design/2021/04/20/moooi-cura-la-realizzazione-della-hortensia-armchair-di-andres-reisinger-julia-esque/",
  },
  {
    press: "Design Wanted",
    url: "https://designwanted.com/design/hortensia-armchair-interview-andres-reisinger-and-julia-esque-for-moooi/",
  },
  {
    press: "AD Italia",
    url: "https://www.ad-italia.it/news/2021/04/20/sedersi-su-trentamila-petali-di-fiori/",
  },
  {
    press: "AD Russia",
    url: "https://www.admagazine.ru/design/kreslo-gortenziya-ot-moooi",
  },
  {
    press: "Dezeen",
    url: "https://www.dezeen.com/2021/04/26/hortensia-chair-moooi-andres-reisinger-julia-esque-design/",
  },
];
