import { ReactElement } from "react";
import { TWITTER, INSTAGRAM, MAIN_WEB } from "../../constants/ExternalLinks";
import Contact from "../Contact";
import About from "../About";
import styles from "./Navbar.module.scss";
import ButtonCollapse from "../ButtonCollapse";

const Navbar = (): ReactElement => (
  <nav id="top" className={styles.container}>
    <div className={styles.topLinks}>
      <a href={MAIN_WEB} target="_blank" rel="noopener noreferrer">
        REISINGER STUDIO
      </a>
      <p className={styles.socialMedia}>
        <a href={INSTAGRAM} target="_blank" rel="noopener noreferrer">
          IG
        </a>{" "}
        /{" "}
        <a href={TWITTER} target="_blank" rel="noopener noreferrer">
          TW
        </a>
      </p>
    </div>

    <div className={styles.menu}>
      <ButtonCollapse name="CONTACT">
        <Contact />
      </ButtonCollapse>
      <ButtonCollapse name="ABOUT">
        <About />
      </ButtonCollapse>

      <a href={MAIN_WEB} className={styles.option}>
        <p>[↗]</p> BACK TO HOME
      </a>
    </div>
  </nav>
);

export default Navbar;
