import { ReactElement } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NftGrid from "../NftGrid";
import Header from "../Header";
import Navbar from "../Navbar";
import Container from "../Container";
import BackToTop from "../BackToTop";
import Footer from "../Footer";
import { NFT_GRID } from "../../constants";

const App = (): ReactElement => (
  <Router>
    <Container>
      <Header />
      <Navbar />
      <Switch>
        <Route path={NFT_GRID} component={NftGrid} exact />
      </Switch>
      <BackToTop />
    </Container>
    <Footer />
  </Router>
);

export default App;
