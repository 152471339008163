import { ReactElement, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import NftItem from "../NftItem";
import styles from "./NftGrid.module.scss";
import useGetAssetsFromApi from "../../hooks/requests/useGetAssetsFromApi";
import Loader from "../Loader";
import { TAsset } from "../../types";
import { API_DEFAULT_LIMIT } from "../../constants/env";

const NftGrid = (): ReactElement => {
  const { isLoading, getAssets } = useGetAssetsFromApi();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [assets, setAssets] = useState<TAsset[]>([]);

  useEffect(() => {
    handleGetAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetAssets = async () => {
    const assetsList = await getAssets(page);

    setHasMore(
      assetsList.length !== 0 && assetsList.length === API_DEFAULT_LIMIT
    );
    setAssets([...assets, ...assetsList]);
    setPage((page) => page + 1);
  };

  const fetchData = () => {
    handleGetAssets();
  };

  return (
    <div className={styles.container} data-testid={"nft-list"}>
      {assets.length === 0 && isLoading ? (
        <Loader />
      ) : assets.length > 0 ? (
        <InfiniteScroll
          dataLength={assets.length}
          next={fetchData}
          hasMore={hasMore}
          loader={<Loader />}
        >
          {assets.map(
            ({
              id,
              media,
              publish_date,
              title,
              edition,
              platform,
              lastPrice,
              publish_year,
              opensea_link,
              order,
            }) => (
              <NftItem
                key={id}
                id={id}
                media={media}
                title={title}
                edition={edition}
                platform={platform}
                lastPrice={lastPrice}
                publish_date={publish_date}
                publish_year={publish_year}
                opensea_link={opensea_link}
                order={order}
              />
            )
          )}
        </InfiniteScroll>
      ) : (
        <div className={styles.noResults}>
          <span>No NFTs found</span>
        </div>
      )}
    </div>
  );
};

export default NftGrid;
