import axios from "axios";
import { useState } from "react";
import { ASSETS } from "../../constants/apiEndpoints";
import {
  API_BASE_URL,
  API_CLIENT_ID,
  API_DEFAULT_LIMIT,
  API_DEFAULT_ORDER,
  API_DEFAULT_SORT,
} from "../../constants/env";
import { TAsset } from "../../types/asset";
import { apiResponseToAsset } from "../../utils/scripts/AssetsHelper";
import { IAsset } from "../../utils/interfaces";

interface IUseAssetsFromApi {
  isLoading: boolean;
  getAssets: (page: number) => Promise<TAsset[]>;
}

const useGetAssetsFromApi = (): IUseAssetsFromApi => {
  const [isLoading, setIsLoading] = useState(true);
  const getAssets = async (page: number): Promise<TAsset[]> => {
    setIsLoading(true);
    const assets: TAsset[] = [];

    await axios
      .get(`${API_BASE_URL}${ASSETS}`, {
        params: {
          limit: API_DEFAULT_LIMIT,
          offset: page * API_DEFAULT_LIMIT,
          sort: API_DEFAULT_SORT,
          order: API_DEFAULT_ORDER,
          "client.uuid": API_CLIENT_ID,
          "config.show": true,
        },
      })
      .then(({ data }) => {
        data.map((apiAsset: IAsset) =>
          assets.push(apiResponseToAsset(apiAsset))
        );
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));

    return assets;
  };

  return {
    isLoading,
    getAssets,
  };
};

export default useGetAssetsFromApi;
