import { ReactElement, useState } from "react";
import styles from "./BackToTop.module.scss";

const BackToTop = (): ReactElement => {
  const [verticalPosition, setVerticalPosition] = useState(0);

  window.onscroll = function (e) {
    setVerticalPosition(window.pageYOffset);
  };

  return (
    <a href="#top" className={styles.container}>
      <p className={verticalPosition > 800 ? styles.show : ""}>
        [ BACK TO TOP ↑ ]
      </p>
    </a>
  );
};

export default BackToTop;
