import { TAsset } from "../../types";
import { IAsset } from "../interfaces";
import moment from "moment";

export const apiResponseToAsset = ({
  _id,
  name,
  asset_contract,
  permalink,
  image_url,
  animation_url,
  config,
}: IAsset): TAsset => ({
  id: _id,
  title: name,
  edition: config.description,
  platform: asset_contract.name,
  order: config.order,
  lastPrice: config.price,
  opensea_link: permalink,
  publish_date: config.release_date
    ? moment(config.release_date).format("MMMM YY")
    : "",
  publish_year: config.release_date
    ? moment(config.release_date).format("YYYY")
    : "",
  media: animation_url ?? image_url,
});
