import { ReactElement } from "react";
import ARROW_OPEN from "../../assets/images/arrow-open.png";
import WETH_ICON from "../../assets/images/weth-icon.png";
import styles from "./NftItem.module.scss";
import Player from "../Player";

interface INftItem {
  id: string;
  media: string;
  title: string;
  edition: string;
  platform: string;
  lastPrice?: number;
  publish_date: string;
  publish_year: string;
  opensea_link: string;
  order?: number;
}

const NftItem = ({
  media,
  title,
  edition,
  platform,
  lastPrice,
  publish_date,
  publish_year,
  opensea_link,
  order,
}: INftItem): ReactElement => (
  <div className={styles.container}>
    <div className={styles.artPiece}>
      {order && <p>{order}.</p>}
      <Player media={media} title={title} />
    </div>
    <div className={styles.verticalLine}></div>
    <div className={styles.artInfo}>
      <div>
        {publish_date && <p>{publish_date}.</p>}
        {publish_year && <p>.{publish_year}</p>}
      </div>
      <p className={styles.description}>
        {title} <br />
        {edition && (
          <>
            {edition}
            <br />
          </>
        )}
        {platform && (
          <>
            {platform}
            <br />
          </>
        )}
        {lastPrice && (
          <span>
            <img src={WETH_ICON} alt="weth" /> {lastPrice}
          </span>
        )}
      </p>
      <a href={opensea_link} target="_blank" rel="noopener noreferrer">
        [ LEARN MORE ] <img src={ARROW_OPEN} alt="learn more" />
      </a>
    </div>
    <div className={styles.compactNftView}>
      <div className={styles.topInfo}>
        {order && <p>{order}.</p>}
        {publish_date && <p>{`${publish_date} ${publish_year}.`}</p>}
      </div>
      <Player media={media} title={title} />
      <div className={styles.description}>
        <p>
          {title} <br />
          {edition && (
            <>
              {edition}
              <br />
            </>
          )}
          {platform && (
            <>
              {platform}
              <br />
            </>
          )}
          {lastPrice && (
            <span>
              <img src={WETH_ICON} alt="weth" /> {lastPrice}
            </span>
          )}
        </p>
        <a href={opensea_link} target="_blank" rel="noopener noreferrer">
          [ MORE ] <img src={ARROW_OPEN} alt="learn more" />
        </a>
      </div>
    </div>
  </div>
);

export default NftItem;
