import styles from "./Loader.module.scss";
import loadingGif from "../../assets/images/loading.gif";

const Loader = (): JSX.Element => (
  <div className={styles.container}>
    <img src={loadingGif} alt="loading..." />
  </div>
);

export default Loader;
