import { ReactElement } from "react";
import {
  TWITTER,
  INSTAGRAM,
  REISINGER_MAIL_CONTACT,
  REISINGER_MAIL_PRESS,
} from "../../constants/ExternalLinks";
import styles from "./Contact.module.scss";

const Contact = (): ReactElement => (
  <div className={styles.container}>
    <a
      href={`mailto:${REISINGER_MAIL_CONTACT}`}
      target="_blank"
      rel="noreferrer"
    >
      <p>·</p> info@reisinger.studio
    </a>
    <a href={`mailto:${REISINGER_MAIL_PRESS}`} target="_blank" rel="noreferrer">
      <p>·</p> press@reisinger.studio
    </a>
    <a
      href="https://www.google.com/maps/place/Carrer+de+Llull,+70,+08005+Barcelona,+Espa%C3%B1a/data=!4m2!3m1!1s0x12a4a31a0e4bb789:0xeb580f9a9aca91d8?sa=X&amp;ved=2ahUKEwiY8bvT5aLuAhUBDmMBHR0mBT4Q8gEwAHoECAMQAQ"
      target="_blank"
      rel="noreferrer"
    >
      <p>·</p> C/ Espronceda, 123. 08005 Barcelona, Spain
    </a>
    <a href={INSTAGRAM} target="_blank" rel="noopener noreferrer">
      <p>·</p> Instagram
    </a>
    <a href={TWITTER} target="_blank" rel="noopener noreferrer">
      <p>·</p> Twitter
    </a>
  </div>
);

export default Contact;
