import { ReactElement, useEffect, useState } from "react";
import styles from "./Header.module.scss";
import "react-slidedown/lib/slidedown.css";
import { SlideDown } from "react-slidedown";

const Header = (): ReactElement => {
  const [isClosed, setIsClosed] = useState(false);

  function setHeader() {
    let element = document.getElementById("top");

    if (window.scrollY === 0) {
      setTimeout(function () {
        setIsClosed(false);
      }, 500);
    } else if (window.scrollY > element?.clientHeight!) {
      setIsClosed(true);
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", setHeader);
    }

    watchScroll();
    return () => {
      window.removeEventListener("scroll", setHeader);
    };
  });

  return (
    <SlideDown
      transitionOnAppear={false}
      className={styles.slideDown}
      closed={isClosed}
    >
      <header className={styles.container}>
        <p>UNCLASSIFIABLE STUDIO BRIDGING THE IMAGINED AND THE TANGIBLE —</p>
        <p>FOUNDED AND DIRECTED BY ARTIST ANDRÉS REISINGER.</p>
      </header>
    </SlideDown>
  );
};

export default Header;
