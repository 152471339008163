import { ReactElement, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { Waypoint } from "react-waypoint";

const Player = ({
  media,
  title,
}: {
  media: string;
  title: string;
}): ReactElement => {
  let [shouldPlay, updatePlayState] = useState(false);

  let handleEnterViewport = function () {
    updatePlayState(true);
  };
  let handleExitViewport = function () {
    updatePlayState(false);
  };

  return (
    <>
      {media?.indexOf(".mp4") !== -1 ? (
        <Waypoint
          topOffset={"25%"}
          bottomOffset={"25%"}
          scrollableAncestor={window}
          onEnter={handleEnterViewport}
          onLeave={handleExitViewport}
        >
          <div>
            <ReactPlayer
              controls={false}
              volume={0.5}
              muted={true}
              loop={true}
              playing={shouldPlay}
              url={media}
            />
          </div>
        </Waypoint>
      ) : (
        <img src={media} alt={title} />
      )}
    </>
  );
};

export default Player;
